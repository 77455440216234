import { CSSProperties } from "react";
import { get } from "lodash";
import { ISettings, ComponentTypes } from "./types";

export const getWebComponentStyle = (settings: ISettings) => {
  const { type, canHaveChildren = false, properties = {} } = settings;

  const style: CSSProperties = {
    width: "100%",
    display: get(properties, "display", "block"),
    textAlign: get(properties, "textAlign", "left"),
    position: get(properties, "position", "relative"),
    ...(type !== ComponentTypes.COLUMN ? { marginTop: -1, paddingTop: 1 } : {}), // FIX: solves issue where border offset with inner obj margin-top
    ...(type === ComponentTypes.POPUP ? { position: "unset" } : {}),
  };

  return canHaveChildren || type === ComponentTypes.COLUMNS
    ? {
        ...style,
        justifyContent: get(properties, "justifyContent", "flex-start"),
        height: get(properties, "height", "unset"),
      }
    : style;
};
