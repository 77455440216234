import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import style from "./style";
import reducers from "@launchos/plugins/webcomponents/v2/Button/reducers";

export const PopupOuter = props => {
  const { settings, live } = props;

  return (
    <React.Fragment>
      {_.get(props.settings, "isPopupBackgroundShowing", true) && (
        <div
          style={{
            ...style.popupBG,
            ...(live && { position: "fixed" })
          }}
        />
      )}
      <div
        style={{
          ...style.popupBehind,
          ...(live && { position: "fixed" })
        }}
      >
        <div
          id="popup"
          style={{
            ...style.popup,
            width: _.get(settings.properties, "width", "inherit"),
            maxWidth: _.get(settings.properties, "maxWidth", "inherit"),
            margin: _.get(settings.properties, "margin", "0 auto"),
            marginTop: _.get(settings.properties, "marginTop", 0),
            ...(settings.popupType === "sidebar" && {
              position: "fixed",
              top: 0,
              right: 0,
              marginTop: 0
            })
          }}
        >
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

export const PopupInner = props => {
  const { settings } = props;

  return (
    <div
      style={{
        ...settings.properties,
        marginTop: 0,
        ...(settings.popupType === "sidebar" && {
          height: "100vh"
        })
      }}
    >
      {props.children}
    </div>
  );
};

const Popup = props => {
  const {
    dispatch,
    settings,
    updateComponentSettings,
    getComponentSettings
  } = props;
  return (
    <PopupOuter {...props} live>
      {_.get(settings, "isCloseButtonShowing", true) && (
        <div
          style={style.popupClose}
          onClick={() =>
            dispatch({
              behavior: "click",
              type: "HIDE_POPUP",
              payload: { hideId: settings.id },
              settings,
              updateComponentSettings,
              getComponentSettings
            })
          }
        />
      )}
      <PopupInner {...props} />
    </PopupOuter>
  );
};

export default connect()(Popup);
