import { doUpsell, getInputVals, sendData } from "@launchos/modules/editor/Builder/Page/scripts";
import _ from "lodash";

const showButtonLoader = action => {
  action.updateComponentSettings(action.settings.id, {
    ...action.settings,
    html: "Processing...",
    disabled: true,
    oldHtml: action.settings.html
  });
};

const hideButtonLoader = action => {
  const oldSettings = action.getComponentSettings(action.settings.id);
  action.updateComponentSettings(action.settings.id, {
    ...action.settings,
    disabled: false,
    html: _.get(oldSettings, "oldHtml", "Continue...")
  });
};

export const triggerNextPage = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const url = _.get(_.head(document.getElementsByName("nxP")), "value");
  console.log({ url });
  window.location.href = url ? `/${url}` : "#";
}

export const submitForm = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const inputVals = getInputVals();
  const inputValsJson = JSON.stringify(inputVals);
  console.log('submitting', { action })
  showButtonLoader(action);

  // send the form contents to the selected crm integration
  sendData(inputValsJson, (res = true) => {
    if (res) {
      let url = _.get(action, "payload.url", action.settings.href);
      if (action.payload.useTag) url = localStorage.getItem(url);

      window.location.href = url;
    } else {
    }
  });
}

export const triggerUpsell = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const url = action.settings.href;
  if (localStorage.hasOwnProperty("orderResponse")) {
    const orderResponse = JSON.parse(localStorage.orderResponse);
    const sendableOrderResponse = _.toPairs(orderResponse).map((i, k) => ({
      name: i[0],
      value: i[1]
    }));

    const inputVals = [...sendableOrderResponse, ...getInputVals()];
    const inputValsJson = JSON.stringify(inputVals);

    showButtonLoader(action);

    doUpsell(inputValsJson, (res = true) => {
      if (res) {
        window.location.href = url;
      } else {
        hideButtonLoader(action);
      }
    });
  } else {
    hideButtonLoader(action);
    alert("Sorry, we are unable to locate your previous order!");
  }
}

export const triggerPurchase = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const inputVals = [
    ...getInputVals(),
    {
      name: "purchaseType",
      value: _.get(action, "payload.purchaseType", "default")
    },
    { name: "shoppingCartItems", value: _.get(localStorage, "cart", "[]") }
  ];

  showButtonLoader(action);

  const inputValsJson = JSON.stringify(inputVals);
  const toCheck = inputVals.filter(
    itm => itm.name !== "emP" && itm.name !== "pmP" && itm.name !== "nxP"
  );

  if (toCheck.length) {
    // send the form contents to the selected crm integration
    sendData(inputValsJson, (res = true) => {
      if (res) {
        window.location.href = action.settings.href;
      } else {
        hideButtonLoader(action);
      }
    });
  } else {
    window.location.href = action.settings.href;
  }
}

export const goToUrl = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  let url = _.get(action, "payload.url", action.settings.href);
  if (action.payload.useTag) url = localStorage.getItem(url);

  window.location.href = url;
}

export const showAndHide = (action) => {
  const { payload, updateComponentSettings, getComponentSettings } = action;
  const { hideId, showId } = payload;

  const hideIdSettings = getComponentSettings(hideId);
  const showIdSettings = getComponentSettings(showId);

  updateComponentSettings(
    hideId,
    {
      ...hideIdSettings,
      properties: {
        ..._.get(hideIdSettings, "properties", false),
        display: "none",
        mobile: {
          ..._.get(hideIdSettings, "properties.mobile", false),
          fullscreen: {
            ..._.get(hideIdSettings, "properties.mobile.fullscreen", false),
            display: "none"
          },
          desktop: {
            ..._.get(hideIdSettings, "properties.mobile.desktop", false),
            display: "none"
          },
          tablet: {
            ..._.get(hideIdSettings, "properties.mobile.tablet", false),
            display: "none"
          },
          smartphone: {
            ..._.get(hideIdSettings, "properties.mobile.smartphone", false),
            display: "none"
          }
        }
      }
    },
    () => {
      updateComponentSettings(showId, {
        ...showIdSettings,
        properties: {
          ..._.get(showIdSettings, "properties", false),
          display: _.get(showIdSettings, "properties.oldDisplay", "block"),
          mobile: {
            ..._.get(showIdSettings, "properties.mobile", false),
            fullscreen: {
              ..._.get(showIdSettings, "properties.mobile.fullscreen", false),
              display: _.get(showIdSettings, "properties.oldDisplay", "block")
            },
            desktop: {
              ..._.get(showIdSettings, "properties.mobile.desktop", false),
              display: _.get(showIdSettings, "properties.oldDisplay", "block")
            },
            tablet: {
              ..._.get(showIdSettings, "properties.mobile.tablet", false),
              display: _.get(showIdSettings, "properties.oldDisplay", "block")
            },
            smartphone: {
              ..._.get(showIdSettings, "properties.mobile.smartphone", false),
              display: _.get(showIdSettings, "properties.oldDisplay", "block")
            }
          }
        }
      });
    }
  );
}

export const openPopup = (action) => {
  const { payload, updateComponentSettings, getComponentSettings } = action;
  const { showId } = payload;
  const showIdSettings = getComponentSettings(showId);

  console.log({ action, showIdSettings, showId });
  if (showIdSettings) {
    updateComponentSettings(showId, {
      ...showIdSettings,
      properties: {
        ..._.get(showIdSettings, "properties", false),
        display: _.get(showIdSettings, "properties.oldDisplay", "block"),
        mobile: {
          ..._.get(showIdSettings, "properties.mobile", false),
          fullscreen: {
            ..._.get(showIdSettings, "properties.mobile.fullscreen", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          desktop: {
            ..._.get(showIdSettings, "properties.mobile.desktop", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          tablet: {
            ..._.get(showIdSettings, "properties.mobile.tablet", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          smartphone: {
            ..._.get(showIdSettings, "properties.mobile.smartphone", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          }
        }
      }
    });
  }
}

export const hidePopup = (action) => {
  const { payload, updateComponentSettings, getComponentSettings } = action;
  const { hideId } = payload;
  const hideIdSettings = getComponentSettings(hideId);
  // alert("Testing")

  console.log({ action, hideIdSettings, hideId });

  updateComponentSettings(hideId, {
    ...hideIdSettings,
    properties: {
      ..._.get(hideIdSettings, "properties", false),
      display: "none",
      mobile: {
        ..._.get(hideIdSettings, "properties.mobile", false),
        fullscreen: {
          ..._.get(hideIdSettings, "properties.mobile.fullscreen", false),
          display: "none"
        },
        desktop: {
          ..._.get(hideIdSettings, "properties.mobile.desktop", false),
          display: "none"
        },
        tablet: {
          ..._.get(hideIdSettings, "properties.mobile.tablet", false),
          display: "none"
        },
        smartphone: {
          ..._.get(hideIdSettings, "properties.mobile.smartphone", false),
          display: "none"
        }
      }
    }
  });
}

export const createTag = (action) => {
  const { payload } = action;
  const { tagName, tagValue } = payload;
  localStorage.setItem(tagName, tagValue);
}

export default {
  triggerNextPage,
  submitForm,
  triggerUpsell,
  triggerPurchase,
  goToUrl,
  showAndHide,
  openPopup,
  hidePopup,
  createTag,
}