import * as React from "react";
import {
  EditorMode,
  EditorComponentProps,
} from "@launchos/modules/v2/Editor/types";

import { IconSource, IconTypes } from "../Icon/types";
import { ILinks } from "@launchos/modules/v2/CRUD/AttributeBuilder/LinkSelector/types";

export enum NavigationLayouts {
  STANDARD,
  FOOTER_STANDARD,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  TEN,
}

export enum LinkDisplayTypes {
  TEXT = "Text",
  ICON = "Icon",
  BUTTON = "Button",
  HAMBURGER = "Hamburger",
}

export const ItemTypes = LinkDisplayTypes;

export enum LinkLayoutStyles {
  HORIZONTAL = "Horizontal",
  VERTICAL = "Vertical",
}

export type NavigationItemType = {
  /**
   * Caption of the first line of the button
   **/
  id: string;
  caption?: string;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  iconSource?: IconSource;
  icon?: IconTypes;
};

export interface NavigationProps {
  mode?: EditorMode;
  id?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  icon?: IconTypes;
  iconSource?: IconSource;
  data: NavigationItemType[];
  layoutStyle?: LinkLayoutStyles;
}

export interface NavigationBuilderProps extends NavigationProps {
  onChange: any;
  links?: ILinks[];
}

export interface PluginProps extends EditorComponentProps {
  settings: NavigationProps;
}

export interface PropertiesProps extends EditorComponentProps {
  settings: any;
}
