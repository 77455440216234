export default {
  // backgroundColor: "#2c8ac9",
  // backgroundColor: "#0045FE",
  // backgroundColor: "#222",
  backgroundColor: "#0022AA",
  foregroundColor: "#FFF",
  highlightColor: "#e43b2c",
  // fontFamily: "Roboto",
  fontFamily: "Open Sans",
  // fontFamily: "Poppins",
  // logoLight: "https://medialibdata.s3.amazonaws.com/2/tmflogo_footer.png",
  logoLight: "http://sandcastleassets.s3.amazonaws.com/images/LogoLight.png",
  logoDark: "http://sandcastleassets.s3.amazonaws.com/images/LogoDark.png",
  // logoDark:
  //   "https://medialibdata.s3.amazonaws.com/457/10MinuteFunnels_Logo_RGB.png",
  logoIcon: "https://s3.amazonaws.com/sandcastleassets/images/logo_icon.png",
  // logoIcon:
    // "https://www.publicdomainpictures.net/pictures/350000/nahled/yin-yang-symbol.png",
};
