import { CSSProperties } from "react";

const style: {
  popupBG: CSSProperties;
  popupBehind: CSSProperties;
  popupClose: CSSProperties;
  selectField: CSSProperties;
} = {
  popupBG: {
    backgroundColor: "black",
    opacity: 0.7,
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 200,
  },
  popupBehind: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 200,
  },
  popupClose: {
    width: 33,
    height: 31,
    backgroundImage: `url(https://s3.amazonaws.com/sandcastleassets/images/close_btn.png)`,
    float: "right",
    marginTop: 20,
    marginRight: 20,
    cursor: "pointer",
    position: "relative",
  },
  selectField: {
    padding: 10,
    color: "#666",
    border: "1px solid #DDD",
    borderRadius: 3,
    cursor: "pointer",
  },
};

export default style;
