import { EditorMode } from "@launchos/modules/v2/Editor/types";
import tabSettings from "./settings";

export const addNewTab = ({
  settings = { items: [] },
  pageContent = [],
  updateContent = () => null,
  updateComponentSettings = () => null,
}) => {
  // deactivate tabs, clone last one, and make active
  const newId = Math.random().toString(36).slice(2);
  const items = [
    ...settings.items.map((itm) => ({
      ...itm,
      active: false,
    })),
    {
      ...settings.items[settings.items.length - 1],
      id: newId,
      active: true,
    },
  ];

  const key = pageContent.findIndex((itm) => itm.id === settings.id);
  const newContent = [
    ...pageContent.slice(0, key + 1),
    {
      ...tabSettings.default.children[0],
      itemId: newId,
      parent: settings.id,
      id: newId,
    },
    ...pageContent.slice(key + 1),
  ];

  // console.log({ pageContent, newContent, settings, key });
  updateContent(newContent, true, false, () => {
    // console.log("Content Updated");
    updateComponentSettings(
      settings.id,
      { ...settings, items },
      true,
      false
      // () => {
      // console.log("Tabs Updated");
      // }
    );
  });
};

export const switchTab = ({
  settings = { id: "", items: [] },
  updateComponentSettings = () => null,
  tabId = "",
  mode = EditorMode.LIVE,
}) => {
  const items = settings.items.map((itm) => ({
    ...itm,
    active: itm.id === tabId,
  }));
  if (mode === EditorMode.EDITOR)
    updateComponentSettings(settings.id, { ...settings, items }, true, false);
  else updateComponentSettings(settings.id, { ...settings, items });
};
