import { get } from "lodash";
import React, { Component } from "react";

import { getResponsiveSettings, sendData } from "./scripts";
import { WebComponent } from "@launchos/modules/editor/Builder/WebComponent/WebComponent";
// const { WebComponent } = React.lazy(() => import("@launchos/modules/editor/Builder/WebComponent/WebComponent"))
import { triggerHook } from "@launchos/plugins/live";

import { ComponentRenderHooks, HookTriggers } from "@launchos/plugins/types";

import { generateFontList } from "@launchos/modules/editor/scripts";
import { MobileState } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/types";

import { beforeContentLoads } from "@launchos/modules/editor/Builder/Page/scripts/preProcessContent";

import "./index.css";
import { EditorMode } from "../../../v2/Editor/types";
import WebFontLoader from "webfontloader";

export class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: JSON.parse(unescape(get(props, "content", "[]"))),
      currentMobileState: { type: MobileState.FULLSCREEN },
    };

    this.generatePage = this.generatePage.bind(this);
    this.updateComponentSettings = this.updateComponentSettings.bind(this);
    this.updateComponentStyle = this.updateComponentStyle.bind(this);
    this.getComponentSettings = this.getComponentSettings.bind(this);
  }

  async fetchContent(id, callbackFn = () => null) {
    // const localSubdomain = `cWEtdGVzdC1reWxlLTA1LnRlbm1pbnV0ZWZ1bm5lbHMuY29t`;
    // const localSubdomain = `bGF1bmNob3MudGVubWludXRlZnVubmVscy5jb20=` // launchos.tenminutefunnels.com
    const localSubdomain = `ZGVib3JhaGhhcnJpczIwLnRlbm1pbnV0ZWZ1bm5lbHMuY29t` // deboraharris20.tenminutefunnels.com;
    // const localSubdomain = `c3RlcGhlbm1vbml6OTEudGVubWludXRlZnVubmVscy5jb20`; // stephenmoniz91.tenminutefunnels.com
    
    const ztx = atob;

    const host = window.location.hostname === "localhost" ?
      `sandcastlesites.s3-website-us-east-1.amazonaws.com/${ztx(localSubdomain)}`
      : window.location.hostname;

    const protocol = window.location.protocol;
    const url = `${protocol}//${host}/pages/${id}.json`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Origin: window.location.origin,
      },
    });

    const result = await response.json();

    // Pre-process the content before it loads.
    const content = await beforeContentLoads({
      pageId: id,
      content: result,
      nextPage: this.props.nextPage,
      getPageData: this.props.getPageData,
      trackingPayload: JSON.parse(this.props.trackingPayload),
    });

    this.setState({ content }, callbackFn)

  };

  async componentDidMount() {
    // const isBrowser = typeof window !== "undefined"
    // if (!isBrowser) return null;

    const loadFonts = () => {

      // load fonts
      const fonts = generateFontList(this.state.content);

      if (fonts.length) {
        WebFontLoader.load({
          google: {
            families: fonts,
          },
        });
      }

    }

    // -> if content is empty, fetch it from s3 <-
    if (!this.state.content.length || !this.state.content) {
      this.fetchContent(this.props.id)
    } else {
      loadFonts()
    }

  }

  updateComponentStyle(id, newStyle, callbackFn = () => false) {
    const { content } = this.state;

    const key = content.findIndex((itm) => itm.id === id);
    const settings = content[key];

    const updatedSettings = {
      ...settings,
      properties: {
        ...settings.properties,
        ...newStyle,
      },
    };

    this.updateComponentSettings(id, updatedSettings, callbackFn);
  }

  updateComponentSettings(id, settings, callbackFn = () => false) {
    const { content } = this.state;
    const key = content.findIndex((itm) => itm.id === id);

    this.setState(
      {
        content: [
          ...content.slice(0, key),
          settings,
          ...content.slice(key + 1),
        ],
      },
      callbackFn
    );
  }

  getComponentSettings(id) {
    const { content } = this.state;
    const key = content.findIndex((itm) => itm.id === id);
    return content[key];
  }

  generatePage(content = [], parent = false) {
    const { currentMobileState } = this.state;
    const node = [];
    let i = 0;
    content
      .filter((c) => c.parent === parent)
      .forEach((c) => {
        i += 1;
        const props = {
          settings: getResponsiveSettings(c, currentMobileState),
          key: c.id,
          sendData,
          updateComponentSettings: this.updateComponentSettings,
          updateComponentStyle: this.updateComponentStyle,
          getComponentSettings: this.getComponentSettings,
          pageContent: this.state.content,
          pageId: this.props.id,
        };

        const internal = c.html || this.generatePage(content, c.id);
        node[i] = (
          <WebComponent
            {...props}
            type={c.type}
            getMobileState={() => currentMobileState}
            mode={EditorMode.LIVE}
            triggerHook={triggerHook}
          >
            {internal}
          </WebComponent>
        );
      });

    return node;
  }

  render() {
    const {
      nextPage = "",
      seoPayload = {},
      merchantPayload = {},
      trackingPayload = {},
      emailPayload = {},
      cssPayload = "",
      exitPayload = {},
      id,
      campaignId = "",
      userId = "",
    } = this.props;

    return (
      <>
        {
          triggerHook(
            HookTriggers.onComponentRender,
            { id: ComponentRenderHooks.BODY_START }, { trackingPayload }
          )
        }
        {this.state.content && this.generatePage(this.state.content)}
        {triggerHook(
          HookTriggers.onComponentRender,
          {
            id: ComponentRenderHooks.PAGE_LOAD,
          },
          {
            pageId: id,
            campaignId,
            userId,
            cssPayload,
            exitPayload,
            seoPayload,
            trackingPayload,
            nextPage,
            merchantPayload,
            emailPayload,
            pageContent: this.state.content,
          },
          {
            getComponentSettings: this.getComponentSettings,
            updateComponentSettings: this.updateComponentSettings,
            updateComponentStyle: this.updateComponentStyle,
            setMobileState: (newState) =>
              this.setState({ currentMobileState: newState }),
          }
        )}

        {
          triggerHook(HookTriggers.onComponentRender,
            { id: ComponentRenderHooks.BODY_END },
            { trackingPayload }
          )
        }
      </>
    );
  }
}

export default Page;
