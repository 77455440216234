import { findDOMNode } from "react-dom";
import { HoverCursorMethods } from "./types";

const getInitialVariables = (props, monitor, component) => {
  // Determine coordinates of the object i'm on
  const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

  // Get the corrdinates of the middle of the object
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

  // Where is my mouse?
  const clientOffset = monitor.getClientOffset();

  // Where is the mouse coordinates with respect to the object I'm on?
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;

  // only add a cursor if the mouse has changed directions
  if (hoverClientY === monitor.getItem().formerClientY) return false; // ignore if mouse doesn't move

  const { pageContent, settings, listAncestors, addHoverCursor } = props;

  return {
    hoverBoundingRect,
    hoverMiddleY,
    hoverClientY,
    sourceItem: monitor.getItem().settings,
    targetItem: settings,
    pageContent,
    listAncestors,
    addHoverCursor,
  };
};

const isContainerEmpty = ({ pageContent, targetItem }) =>
  pageContent
    .filter((itm) => _.get(itm, "properties.display", false) !== "none")
    .findIndex((itm) => itm.parent === targetItem.id) === -1;

const targetCanHaveChildren = ({ targetItem }) => targetItem.canHaveChildren;

const determineMouseMoveDirection = (
  { hoverClientY, hoverMiddleY },
  monitor
) => {
  const toRet = hoverClientY < (monitor.getItem().formerClientY || hoverMiddleY) ? "UP" : "DOWN";
  monitor.getItem().formerClientY = hoverClientY; // set current direction
  return toRet;
};

const doProceed = (variables, monitor, component) => {
  if (!variables) return false;

  const { sourceItem, listAncestors, pageContent, targetItem } = variables;

  if (_.get(component, "state.isEditing")) return false;

  // only proceed if hovering directly on the object (and not nested inside)
  if (!monitor.isOver({ shallow: true })) return false;

  // Handle Containers that try to drop inside themselves
  if (sourceItem && (sourceItem.canHaveChildren || sourceItem.type === "Columns" || sourceItem.type === "Carousel" || sourceItem.type === "Tabs")) {
    const offLimitObjects = listAncestors(pageContent, targetItem.id); // all the parents of the targetItem
    const offLimitIds = offLimitObjects.map((itm) => itm.parent); // all the ids parents
    if (offLimitIds.findIndex((itm) => itm === sourceItem.id) > -1)
      return false; // if we find the id of the sourceItem in my list, cancel
    if (sourceItem.id === targetItem.id) return false;
  }

  // Don't work on a root object (ALWAYS HAVE A "BODY" object as your root!)
  // if (!targetItem.parent) return;

  return true;
};

const addHoverCursor = (id, payload, variables, monitor) => {
  const { calledFrom, addType } = payload;
  const { targetItem, sourceItem, addHoverCursor } = variables;
  const { type, bodyOnly = false } = sourceItem;

  // console.log('Adding Hover Cursor', addType, 'a', targetItem.type)

  if ((type === "Section" || bodyOnly) && targetItem.parent === 2540) { // if we're dragging a section, only allow on body
    if (targetItem.parent === 2540) {
      if (
        monitor.isOver({ shallow: true }) &&
        calledFrom !== HoverCursorMethods.LAST_ITEM_IN_CONTAINER
      ) {
        addHoverCursor(id, addType);
      }
    }
  } else if (type !== "Section" && !bodyOnly) {
    if (targetItem.parent !== 2540 || addType === "inside") {
      addHoverCursor(id, addType);
    }
  }
  return null;
};

const isNearTheBottom = ({ hoverClientY, hoverBoundingRect }) =>
  hoverClientY >
  Math.round(hoverBoundingRect.height - hoverBoundingRect.height * 0.2);

const isNearTheTop = ({ hoverBoundingRect, hoverClientY }) =>
  hoverClientY < Math.round(hoverBoundingRect.height * 0.2);

const isMovingInSameDirection = (currentAddType, variables, monitor) => {
  const { targetItem } = variables;

  const shouldReturn =
    currentAddType === monitor.getItem().addType &&
    targetItem.id === monitor.getItem().activeTargetItem;

  monitor.getItem().addType = currentAddType;
  monitor.getItem().activeTargetItem = targetItem.id;

  return shouldReturn;
};

export default (props, monitor, component) => {
  // get the initial variables that you will need for the rest 
  // of this algorithm
  const variables = getInitialVariables(props, monitor, component);
  const { targetItem } = variables;

  // determine if we should proceed or not
  if (!doProceed(variables, monitor, component)) return;


  // if target is an empty container, add a cursor inside of it
  if (targetCanHaveChildren(variables)) {
    // if the container is empty, add a cursor inside the target
    // if (targetItem.type === "BODY") console.log("Is Body Empty?", isContainerEmpty(variables))

    if (isContainerEmpty(variables)) {


      // disallow sections inside of empty section
      if (variables.sourceItem.type !== "Section") {

        return addHoverCursor(
          targetItem.id,
          {
            addType: "inside",
            calledFrom: HoverCursorMethods.INSIDE_EMPTY_CONTAINER,
          },
          variables,
          monitor
        );

      }

    }
  }

  // If target is a container with content and the mouse is near
  // the edge place the cursor outside
  if (targetCanHaveChildren(variables)) {
    const currentMouseDirection = determineMouseMoveDirection(
      variables,
      monitor
    );

    // if mouse is at the bottom 20% add the cursor after the container
    if (isNearTheBottom(variables) && currentMouseDirection === "DOWN") {
      // console.log("I'm near the bottom of ", targetItem.type);
      const target = (targetItem.type === "Column" || targetItem.type === "Tab") ? targetItem.parent : targetItem.id;
      return addHoverCursor(
        target,
        { addType: "after", calledFrom: HoverCursorMethods.BOTTOM_20_OTHER },
        variables,
        monitor
      ); // didn't see the cursor though
    }

    // if they're close to the top of the container, add the cursor before the first inner object
    if (isNearTheTop(variables) && currentMouseDirection === "UP") {
      // console.log("I'm near the top of ", targetItem.type);
      const target = (targetItem.type === "Column" || targetItem.type === "Tab") ? targetItem.parent : targetItem.id;
      return addHoverCursor(
        target,
        { addType: "before", calledFrom: HoverCursorMethods.TOP_10_OTHER },
        variables,
        monitor
      );
    }

    return;
  }

  // If the target is not a container, add the hover cursor based
  // on if the mouse is moving over it in the up or down direction
  if (!targetCanHaveChildren(variables)) {
    const currentMouseDirection = determineMouseMoveDirection(
      variables,
      monitor
    );
    let currentAddType = currentMouseDirection === "UP" ? "before" : "after";

    // if their close to the edge of a component, place near that edge
    if (isNearTheBottom(variables) && currentMouseDirection === "UP")
      currentAddType = "after";
    if (isNearTheTop(variables) && currentMouseDirection === "DOWN")
      currentAddType = "before";

    // Cancel if mouse is heading in same direction
    if (isMovingInSameDirection(currentAddType, variables, monitor))
      return false;

    return addHoverCursor(
      targetItem.id,
      {
        addType: currentAddType,
        calledFrom: HoverCursorMethods.BEFORE_AFTER_MOUSE_POSITION,
      },
      variables,
      monitor
    );
  }
};