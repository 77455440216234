import { ButtonPresets } from "./types";
import theme from "../../../misc/v2/blocks/weblayouts/theme";

export const BUTTON01 = {
  style: {
    display: "inline-block",
    padding: 15,
    marginTop: -15,
    border: "none",
    backgroundColor: theme.backgroundColor,
    width: "100%",
    borderRadius: 3,
    cursor: "pointer",
  },
  caption1style: {
    color: theme.foregroundColor,
    fontWeight: "bold" as "bold",
    fontSize: "10pt",
    paddingLeft: 30,
    paddingRight: 30,
  },
};

export const BUTTON02 = {
  style: {
    display: "inline-block",
    padding: 10,
    marginTop: -15,
    border: "1px solid #DDD",
    borderRadius: 5,
    background: "transparent",
    width: "100%",
    cursor: "pointer",
  },
  caption1style: {
    fontWeight: "bold" as "bold",
    fontSize: "10pt",
    paddingLeft: 30,
    paddingRight: 30,
  },
};

export default {
  [ButtonPresets.BUTTON01]: BUTTON01,
  [ButtonPresets.BUTTON02]: BUTTON02,
};
