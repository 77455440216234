import { forEach, has } from "lodash";

// Components
import widgets_v1 from "./webcomponents/v1/live";
import widgets_v2 from "./webcomponents/v2/live";
import addons from "./addons/live";
import misc_v1 from "./misc/v1/live";

export const plugins = [...widgets_v1, ...widgets_v2, ...addons, ...misc_v1];

export const triggerHook: any = (name, hook, payload, actions = []) => {
  let toRet = [];
  forEach(plugins, async (itm) => {
    if (has(itm, name)) {
      const res = itm[name](hook, payload, actions); // trigger the function on every plugin that has it
      const response = res;
      forEach(response, (itm) => toRet.push(itm));
    }
  });

  return toRet;
};
