import { get } from 'lodash';

export const getColumnsWrapperStyle = props => ({
  ...get(props, 'settings.properties'),
  justifyContent: get(props, 'settings.properties.justifyContent', 'normal'),
  display: get(props, 'settings.properties.display', 'flex'),
  padding: 0,
  height: get(props, 'settings.properties.height', 'unset'),
  width: get(props, 'settings.properties.width', '100%'),
  marginTop: get(props, 'settings.properties.marginTop', 0),
})

export const getColumnsInnerStyle = props => ({
  display: "flex",
  padding: get(props, 'settings.properties.padding', 0),
  flexWrap: "wrap",
  height: "100%",
  width: "100%" // was having issues with default width (10mf faq section)
})

export const getColumnStyle = props => ({
  ...get(props, 'settings.properties', {}),
  ...props.style,
  ...props.borderStyle,
  width: "100%",
  height: "100%",
})