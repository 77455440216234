// Import the types for the rule group and rule objects
// import { RuleGroupType, RuleType } from "react-querybuilder";
// import { getFieldType } from "./fields";

export const getFieldType = (field) => {
  const numberFields = ["daysAfterFirstVisit", "daysAfterLastVisit", "numPageVisits"];
  const stringFields = ["url"]; 
  const enumFields = ["tags"];
  const dateFields = ["currentDate", "startDate", "endDate"];
  const booleanFields = ["boolTest"];

  if (numberFields.includes(field)) return "number";
  if (stringFields.includes(field)) return "string";
  if (enumFields.includes(field)) return "enum";
  if (dateFields.includes(field)) return "date";
  if (booleanFields.includes(field)) return "boolean";
};


/**
 * Processes a single rule
 * @param rule The rule object to process using the data object
 * @param data The data object to process the rule with
 * @returns The result of the processing (true or false)
 */
export const processRule = (rule, data) => {
  
  // Destructure the rule object to get the field, operator, and value properties
  const { field, operator, value } = rule;

  const fieldValue = data[field];

  // If the operator is "="
  if (operator === "=") {

    // The # Days Since First Visit (number)
    if (getFieldType(field) === "number") return fieldValue === value;

    // The URL Address (string)
    if (getFieldType(field) === "string") return fieldValue === value;

    // The Visitor Tags (enum)
    if (getFieldType(field) === "enum") return value.split(",").every((valueItem) => fieldValue.includes(valueItem));

    // The Current Date (date)
    if (getFieldType(field) === "date") return fieldValue === value;

    // Boolean Test (boolean)
    if (getFieldType(field) === "boolean") return fieldValue === value;

  }

  // If the operator is "<"
  if (operator === "<") {
    // The # Days Since First Visit (number)
    if (getFieldType(field) === "number") return fieldValue < value;

    // The Current Date (date)
    if (getFieldType(field) === "date") return fieldValue < value;

  }

  // If the operator is "<="
  if (operator === "<=") {
    // The # Days Since First Visit (number)
    if (getFieldType(field) === "number") return fieldValue <= value;

    // The Current Date (date)
    if (getFieldType(field) === "date") return fieldValue <= value;

  }

  // If the operator is ">="
  if (operator === ">=") {
    // The # Days Since First Visit (number)
    if (getFieldType(field) === "number") return fieldValue >= value;

    // The Current Date (date)
    if (getFieldType(field) === "date") return fieldValue >= value;
  }

  // If the operator is ">"
  if (operator === ">") {
    // The # Days Since First Visit (number)
    if (getFieldType(field) === "number") return fieldValue > value;

    // The Current Date (date)
    if (getFieldType(field) === "date") return fieldValue > value;

  }

  // If the operator is "!="
  if (operator === "!=") {
    // The # Days Since First Visit (number)
    if (getFieldType(field) === "number") return fieldValue !== value;

    // The URL Address (string)
    if (getFieldType(field) === "string") return fieldValue !== value;

    // The Visitor Tags (enum)
    if (getFieldType(field) === "enum") return !value.split(",").every((valueItem) => fieldValue.includes(valueItem));

    // The Current Date (date)
    if (getFieldType(field) === "date") return fieldValue !== value;

    // Boolean Test (boolean)
    if (getFieldType(field) === "boolean") return fieldValue !== value;

  }

  // If the operator is "between"
  if (operator === "between") {
    // The # Days Since First Visit (number)
    if (getFieldType(field) === "number") return fieldValue >= value.split(",")[0] && fieldValue <= value.split(",")[1];

    // The Current Date (date)
    if (getFieldType(field) === "date") return fieldValue >= value.split(",")[0] && fieldValue <= value.split(",")[1];

  }

  // If the operator is "not between"
  if (operator === "notBetween") {
    // The # Days Since First Visit (number)
    if (getFieldType(field) === "number") return fieldValue < value.split(",")[0] || fieldValue > value.split(",")[1];

    // The Current Date (date)
    if (getFieldType(field) === "date") return fieldValue < value.split(",")[0] || fieldValue > value.split(",")[1];
  }
  
  // If the operator is "contains"
  if (operator === "contains") {
    
    // The URL Address (string)
    if (getFieldType(field) === "string") return fieldValue.includes(value);

    // The Visitor Tags (enum)
    if (getFieldType(field) === "enum") return fieldValue.includes(value);

  }

  // If the operator is "does not contain"
  if (operator === "doesNotContain") {
    // The URL Address (string)
    if (getFieldType(field) === "string") return !fieldValue.includes(value);

    // The Visitor Tags (enum)
    if (getFieldType(field) === "enum") return !fieldValue.includes(value);
  }

  // If the operator is "begins with"
  if (operator === "beginsWith") {
    // The URL Address (string)
    if (getFieldType(field) === "string") return fieldValue.startsWith(value);

    // The Visitor Tags (enum)
    if (getFieldType(field) === "enum") return fieldValue.includes(value);
  }

  // If the operator is "ends with"
  if (operator === "endsWith") {
    // The URL Address (string)
    if (getFieldType(field) === "string") return fieldValue.endsWith(value);

    // The Visitor Tags (enum)
    if (getFieldType(field) === "enum") return fieldValue.includes(value);
  }

  // If the operator is "in"
  if (operator === "in") {
    // The Visitor Tags (enum)
    if (getFieldType(field) === "enum") return value.split(",").every((valueItem) => fieldValue.includes(valueItem));
  }

  // If the operator is "not in"
  if (operator === "notIn") {
    // The Visitor Tags (enum)
    if (getFieldType(field) === "enum") return !value.split(",").every((valueItem) => fieldValue.includes(valueItem));
  }

};

/**
 * Processes an array of rules
 * @param ruleGroup The rule group object to process using the data object
 * @param data The data object to process the rule group with
 * @returns The result of the processing (true or false)
 */
export const processRuleGroup = (
  ruleGroup,
  data
) => {
  // {
  //   "combinator": "and",
  //   "rules": [
  //     {
  //       "field": "url",
  //       "operator": "contains",
  //       "value": "test!"
  //     },
  //     {
  //       "field": "url",
  //       "operator": "contains",
  //       "value": "te",
  //       "id": "s0gok3fyej"
  //     }
  //   ],
  //   "pageId": "clo6eksq9ei2q0811di8rrjb0"
  // }

  // Destructure the rule group object to get the combinator and rules properties
  const { combinator, rules } = ruleGroup;

  // Loop through the rules and process each one
  // To process, use field, operator, and value
  let results = [];
  rules.forEach((rule) => {
    const result = processRule(rule, data);
    results.push(result);
  });

  // Process the combinator
  if (combinator === "and") {
    // If the combinator is "and", return true only if all results are true
    return results.every((result) => result === true);
  } else {
    // If the combinator is "or", return true if any result is true
    return results.some((result) => result === true);
  }
};