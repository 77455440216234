declare module "react-lorem-component";
declare module "react-facebook";

declare module "@launchos/plugins";

export enum ComponentRenderHooks {
  /**
   * The toolbar that shows in the campaign module
   * onComponentRender is triggerred every time it renders. Use it to add components to the toolbar
   */
  CAMPAIGN_SETTINGS_TOOLBAR = "CanvasSettingsToolbar",

  /**
   * Each canvas object that appers in the Advanced Campaign Builder & the Simple Builder
   * onComponentRender - Use it to render components on the node
   */
  CANVAS_OBJECT = "CanvasObject",

  /**
   * The arrow that connects one node to another
   * onComponentRender - Use it to render components to that line
   */
  CONNECTION_LINE = "ConnectionLine",

  /**
   * The component that loads every time a page is visited by a client's visitor
   * onComponentRender - Use it to render custom components on the live page
   */
  PAGE_LOAD = "pageload",

  BODY_START = "bodystart",

  BODY_END = "bodyend",

  /**
   * The component that loads when you open on of the subtabs in the pages tab in the advanced campaign builder's [add new] tab
   * onComponentRender - use it to render custom components in this view
   */
  PAGES_DRAWER = "PagesDrawer",

  /**
   * The component that loads in the editor for every page object listed in the content array
   * The nodes that show in the elements sub tab of the [add new] drawer in the page editor
   * onListItems - use it to add to the list of page elements that show up in the page editor's [add new] drawer
   * onComponentRender - use it to render a custom component in the editor when the content array calls for it
   */
  WEBCOMPONENT = "webcomponent",

  DASHBOARD_PAGE_TITLE = "DashboardPageTitle",
  CATALOG_ITEM_TITLE = "CatalogItemTitle",
  PROPERTIES_TITLE = "PropertiesTitle",
  SECTIONS_TITLE = "SectionsTitle",
  DRAWER_TITLE = "DrawerTitle",

  /**
   * The component that wraps around each page object that loads in the editor
   * onComponentRender - use it to render a custom component that wraps around components as they are rendered in the page editor
   */
  WEBCOMPONENT_WRAPPER = "webcomponentwrapper",
}

export enum ListItemsHooks {
  /**
   * The App Drawer Slider that opens when the hamburger menu is clicked
   * onListItems - add an item to the list of apps
   */
  APP_DRAWER = "AppDrawer",

  /**
   * The dropdown menu in the campaign module's toolbar
   * onListItems - add an item to the list of menu items
   */
  CAMPAIGN_SETTINGS_MENU = "CampaignSettingsMenu",

  /**
   * The Item Drawer in the Advanced Campaign Builder
   * onListItems - add to the list of tabs
   */
  CANVAS_ITEM_DRAWER_TABS = "CanvasItemDrawerTabs",

  /**
   * The nodes that show up in the Destinations tab in the advanced campaign builder's [add new] drawer
   * onListItems - Use it to add to the list of nodes that show up in the list
   */
  DESTINATION_OBJECTS = "DestinationObjects",

  /**
   * The subtabs that show up in the Destinations tab in the advanced campaign builder's [add new] drawer
   * onListItems - Use it to add to the list of tabs that show up in the list
   */
  DESTINATION_NODES_DRAWER_TABS = "DestinationNodesDrawerTabs",

  /**
   * The nodes that show up in the Funnel Actions tab in the advanced campaign builder's [add new] drawer
   * onListItems - use it add to the list of nodes that show up in the list
   */
  FUNNEL_ACTION_OBJECTS = "FunnelActionObjects",

  /**
   * The nodes that show up in the Pages tab in the advanced campaign builder's [add new] tab
   * onListItems - use it to add to the list of page nodes that show up
   */
  PAGE_OBJECTS = "PageObjects",

  /**
   * The subtabs that show up in the Pages tab in the advanced campaign builder's [add new] tab
   * onListItems - use it add to the list of nodes that show up in the list
   */
  PAGES_DRAWER_TABS = "PagesDrawerTabs",

  /**
   * The url routes that clients can access
   * onListItems - use it to add to the urls that clients can access in the app
   * ^^-- return a <Page /> @launchos/modules/app/Page component
   */
  ROUTES = "routes",

  /**
   * The nodes that show up in the Traffic tab in the advanced campaign builder's [add new] drawer
   * onListItems - use it to add to the list of nodes that show up in the list
   */
  TRAFFIC_SOURCE_OBJECTS = "TrafficSourceObjects",

  /**
   * The templates that are available in the page editor
   * onListItems - use it to add to the list of available page templates
   */
  PAGE_TEMPLATE = "template",

  /**
   * The nodes that show in the sections sub tab of the [add new] drawer in the page editor
   * onListItems - use it to add to the list of page section blocks that show up in the page editor's [add new] drawer
   */
  WEB_LAYOUT = "weblayout",

  EDITOR_OBJECT_MENU = "EditorObjectMenu",
}

export enum ComponentMountHooks {
  /**
   * When the app loads for the first time
   * onComponentMount // trigger a js function
   **/
  APP_DID_LOAD = "appDidLoad",

  /**
   * When the campaigns are loaded for the first time
   * onComponentMount // trigger a js function
   **/
  CAMPAIGNS_DID_LOAD = "campaignsDidLoad",
}

export enum HookTriggers {
  /**
   * Use this trigger to render a custom component in the same location as another component
   */
  onComponentRender = "onComponentRender",

  /**
   * Use this trigger to execute a custom function when a component mounts
   */
  onComponentMount = "onComponentMount",

  /**
   * Use this trigger to add a custom item to an existing list
   */
  onListItems = "onListItems",

  onMenuSelect = "onMenuSelect",

  /**
   * Use this trigger to execute a custom function when a node is opened (double-clicked)
   */
  onNodeOpen = "onNodeOpen",

  /**
   *  Use this trigger to execute a custom function when a node is selected (single-clicked)
   */
  onNodeSelect = "onNodeSelect",

  /**
   *  Use this trigger to execute a custom function when a node is created
   */
  onNodeCreate = "onNodeCreate",

  /**
   * Use this trigger to execute a custom NODE JS function when a node is published
   */
  onNodePublish = "onNodePublish",

  /**
   * Use this trigger to execute a custom NODE JS function when a node is triggered
   */
  onNodeTrigger = "onNodeTrigger",
}

export type HookObject = {
  id?: Hooks;
  type?: string;
  position?: "top" | "bottom";
  activeTab?: string;
  // mode;
};
