import React from "react";
import ReactDOM from "react-dom";

import Site from "./Site";

import "./layout.css";

ReactDOM.render(
  <React.StrictMode>
    <Site />
  </React.StrictMode>,
  document.getElementById("funnel")
);
