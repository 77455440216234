import { EditorMode } from "../../../Editor/types";
import { LiveFormFieldProps } from "../LiveFormField/types";

export interface IListItem {
  id: string;
  label: string;
  value?: string;
  key?: number;
}
export interface CheckboxesProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  /**
   * The list of checkboxes
   */
  data: IListItem[];

  /**
   * Triggers when the textarea field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}

export interface ContentFieldProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  /**
   * Triggers when the text field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}

export interface DateFieldProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  /**
   * Triggers when the text field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}

export interface DropdownFieldProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  data: IListItem[];

  /**
   * Triggers when the textarea field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}

export interface FileUploadProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  /**
   * Triggers when the text field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}

export enum LinearScaleDisplayType {
  RADIO,
  SLIDER,
}
export interface LinearScaleFieldProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  displayType?: LinearScaleDisplayType;

  min?: number;
  max?: number;

  /**
   * Triggers when the text field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}

export interface IRadioItem {
  id: string;
  label: string;
  key?: number;
}
export interface MultipleChoiceProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  /**
   * The list of radio buttons
   */
  data?: IListItem[];

  /**
   * Triggers when the textarea field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}

export interface TextAreaFieldProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  /**
   * Triggers when the textarea field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}

export interface TextFieldProps extends LiveFormFieldProps {
  /**
   * Establishes which version of the component to show
   */
  mode?: EditorMode;

  /**
   * A way to globally override the settings key of the attribute container
   * (e.g. to set `hide=false` on all of them)
   */
  attributeContainerDataSettings?: any;

  /**
   * Triggers when the text field is updated • Sends back an updated version of the LiveFormFieldProps payload (e.g. if in live mode, the value would be changed, but if in edit mode, any of the other attributes might be changed)
   */
  // onChange?: (typeOfChange, updatedPayload) => void;
}
