import { fromPairs, get, map, omit } from "lodash";
// import settings from "../../../../../../apps/livesite/src/config";
import settings from "../config";


export default (jsonData, callbackFn) => {
  console.log("Inside Send Data", jsonData);
  const getInputValue = (name, data) =>
    data.findIndex(itm => itm.name === name) > -1
      ? data.filter(itm => itm.name === name)[0].value
      : false;

  const data = JSON.parse(jsonData);
  const credentials = getInputValue("emP", data);
  const mCredentials = getInputValue("pmP", data);

  console.log({ data });
  // console.log('mCredentials', JSON.parse(mCredentials).productId);
  const toSend1 = fromPairs(map(data, i => [i.name, i.value]));
  const toSend = omit(toSend1, ["emP", "pmP", "nxP"]);

  const convertedData = {
    mCredentials,
    // email: getInputValue('email', data),
    ...toSend,
    previousOrderId: getInputValue("order_id", data),
    shippingId: getInputValue("shippingMethod", data) || 2, // TODO: What to do here?  Is 2 appropriate?
    billingModelId: getInputValue("billingModel", data) || 2, // TODO: What to do here?  Is 2 appropriate?
    campaignId: JSON.parse(credentials).campaignId,
    productId: JSON.parse(mCredentials).productId
  };

  console.log({ convertedData });
  const provider = JSON.parse(get(convertedData, "mCredentials", "{}"))
    .provider;
  // const url = `${settings.serverUrl[settings.state]}/api/limelightUpsell`
  const url = `${settings.serverUrl}${settings.upsellUrl[provider]}`;

  console.log({ url });

  const jsonPromise = fetch(url, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(convertedData)
  }).then(r => r.json());

  jsonPromise.then(data => {
    console.log(data);
    // localStorage.setItem('orderResponse', JSON.stringify(data));
    if (data.error_message && data.resp_msg !== "Approved") {
      alert(data.error_message);
      callbackFn(false);
    } else callbackFn();
  }); // this works
};
