import React from "react";
import { connect } from "react-redux";
import style from "./style";

export const BodyContent = (props) => {
  const { settings } = props;
  const { properties = {} } = settings;

  return (
    <div className={`body-${props.mode}`} style={{ ...style.defaultStyle, ...properties, ...props.style }}>
      {props.children}
    </div>
  )
}

const BodyLive = (props) => {
  const {
    dispatch,
    settings,
    updateComponentSettings = () => null,
    getComponentSettings = () => null,
  } = props;

  const { actions, properties = {} } = settings;

  React.useEffect(() => {
    // trigger any web hooks
    if (actions) {
      const doTheseActions = actions.filter(itm => itm.behavior === "click");

      doTheseActions.forEach((action) =>
        dispatch({
          ...action,
          settings,
          updateComponentSettings,
          getComponentSettings,
        })
      );
    }
  }, [])

  return (<BodyContent {...props} />)
};

export default connect()(BodyLive);