import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import * as React from "react";
import ReactCountdown from "react-countdown";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { CountdownProps, CountDownTypes, PluginProps } from "./types";
import { RenderCountdown } from "./RenderCountdown";

import { ComponentTypes } from "../../types";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

export const Countdown: React.FC<CountdownProps> = (props) => {
  const { eventDate, countType, firstVisitExpiration = {} } = props;
  const [eD, setEd] = React.useState<unknown>(Date.now() + 2000)

  React.useEffect(() => {
    console.log({ eventDate, countType, firstVisitExpiration })
    if (countType === CountDownTypes.VISIT) {
      const { minutes = 0, hours = 0, days = 0 } = firstVisitExpiration;
      const timeToAdd = (minutes * 60 * 1000) + (hours * 60 * 60 * 1000) + (days * 24 * 60 * 60 * 1000)
      console.log({ timeToAdd })
      setEd(Date.now() + timeToAdd);
    }
    if (countType === CountDownTypes.TOP_OF_HOUR) {
      let dt = new Date();
      dt.setHours(dt.getHours() + 1 + Math.round(dt.getMinutes() / 60))
      dt.setMinutes(0, 0, 0)
      setEd(dt)
    } else {
      setEd(eventDate)
    }
  }, [])

  return (
    <div data-testid="WC-COUNTDOWN-LIVE">
      <ReactCountdown
        date={eD}
        intervalDelay={0}
        renderer={RenderCountdown(props)}
      />
    </div>
  );
};

Countdown.defaultProps = {
  showDays: true,
  showHours: true,
  showMinutes: true,
  showSeconds: true,
  showLabels: true,
  showDivider: true,
  style: {},
  labelStyle: {},
  dimensionStyle: {},
  dividerStyle: {},
  numberStyle: {},
};

const CountdownPlugin: React.FC<PluginProps> = ({ settings, getComponentSettings, updateComponentSettings }) => {
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings}>
      <Countdown
        getComponentSettings={getComponentSettings}
        updateComponentSettings={updateComponentSettings}
        {...settings}
        mode={EditorMode.LIVE}
        numberStyle={{
          ...settings.numberStyle,
          ...properties,
        }}
      />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.COUNTDOWN
  ) {
    return [CountdownPlugin];
  }
};

export default Countdown;
