import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import * as React from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import GoogleLogin from "react-google-login";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

import { GoogleLoginProps, PluginProps } from "./types";
import { ComponentTypes } from "../../types";

import Button from "../Button/live";

import buttonActions from "@launchos/plugins/webcomponents/v1/button/actions";

export const GoogleLoginComponent: React.FC<GoogleLoginProps> = ({
  style,
  properties,
  clientId = "N/A",
  // buttonText = "Login",
  onSuccess = (response) => console.log({ response }),
  onFailure = (response) => console.log({ response }),
  cookiePolicy = "single_host_origin",
  buttonSettings,
}) => {
  return (
    <div data-testid="WC-GOOGLELOGIN-LIVE" style={style}>
      {/* GoogleLoginLive */}
      <GoogleLogin
        clientId={clientId}
        // buttonText={buttonText}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={cookiePolicy}
        render={(renderProps) => (
          <Button
            {...buttonSettings}
            mode={EditorMode.LIVE}
            style={properties}
            onClick={renderProps.onClick}
          />
        )}
      />
    </div>
  );
};

const GoogleLoginPlugin: React.FC<PluginProps> = ({
  settings,
  dispatch,
  updateComponentSettings,
  getComponentSettings,
}) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const properties = convertProperties(settings.properties);
  const { actions } = settings;

  const handleSuccess = (e) => {
    const { profileObj } = e;
    const { email, givenName, familyName, name, imageUrl } = profileObj;

    // handle the optin submission
    // append two input fields with name and email (if none exists)
    const body = document.getElementsByTagName("BODY")[0];

    // Full Name
    const nameInput = document.createElement("input");
    nameInput.type = "hidden";
    nameInput.name = "name";
    nameInput.value = name;
    body.appendChild(nameInput);

    // First Name
    const firstNameInput = document.createElement("input");
    firstNameInput.type = "hidden";
    firstNameInput.name = "firstName";
    firstNameInput.value = givenName;
    body.appendChild(firstNameInput);

    // Last Name
    const lastNameInput = document.createElement("input");
    lastNameInput.type = "hidden";
    lastNameInput.name = "lastName";
    lastNameInput.value = familyName;
    body.appendChild(lastNameInput);

    // Email Name
    const emailInput = document.createElement("input");
    emailInput.type = "hidden";
    emailInput.name = "email";
    emailInput.value = email;
    body.appendChild(emailInput);

    // submit / then go to url provided in action
    buttonActions.submitForm({
      behavior: "click",
      type: "SUBMIT_FORM",
      payload: {
        ...get(settings, "actions[0].payload", {}),
      },
      settings,
      updateComponentSettings,
      getComponentSettings,
    });
  };

  return (
    <V1ObjectWrapper settings={settings}>
      <GoogleLoginComponent
        {...settings}
        properties={properties}
        onSuccess={handleSuccess}
      />
    </V1ObjectWrapper>
  );
};

const ConnectedGoogleLivePlugin = connect()(GoogleLoginPlugin);

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.GOOGLELOGIN
  ) {
    return [ConnectedGoogleLivePlugin];
  }
};

export default GoogleLoginComponent;
