import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import Text from "./component";

export const onComponentRender = (hook, payload, actions) => {
  // console.log("loading plugin")
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    (payload.type === "Paragraph" || payload.type === "Text")
  ) {
    return [Text];
  }
};
