import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import * as React from "react";
import { get } from "lodash";
import LiveFormField from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField";
// const LiveFormField = () => <div />
import { generateFormComponentStyle } from "./script";

import { PluginProps } from "./types";
import { ComponentTypes } from "../../types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

const FormFieldPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <LiveFormField
        {...settings}
        {...get(settings, "attributes", {})}
        type={settings.fieldType}
        styles={generateFormComponentStyle(properties)}
      />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FORMFIELD
  ) {
    return [FormFieldPlugin];
  }
};

export default LiveFormField;
