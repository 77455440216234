import { get } from "lodash";

export const getDimensionStyle = style => ({
  ...style,
  marginTop: 0,
  textAlign: "center",
  display: "inline-block"
});

export const getDimensionLabelStyle = style => ({
  textAlign: "center",
  textTransform: "uppercase",
  fontFamily: get(style, "fontFamily", "inherit"),
  color: get(style, "color", "inherit"),
  fontSize: "13pt",
  padding: 0,
  margin: 0,
  ...style
});

export const getDividerStyle = style => ({
  border: "none",
  background: "transparent",
  display: "inline-block",
  ...style
});
