import * as React from "react";
import { get } from "lodash";
import LiveFormField from "./LiveFormField";
import { Grid } from "@material-ui/core";

/**
 * A module for displaying, validating, and handling groups of form fields
 *
 * - Displays in either live view or edit view
 * - In "edit view", a drag-and-drop ux is provided to help users build forms
 * - Shows a list sortable object with a custom item container (&lt;EditFormField /&gt;)
 * - In "live view", the form is generated based on the incoming data it receives
 */
export function LiveFormBuilder(props) {
  return (
    <Grid
      container
      justify="space-between"
      spacing={Number(get(props.style, 'containerStyle.spacing', 0))}
      style={get(props.style, 'containerStyle')}
    >
      {/* {JSON.stringify(props)} */}
      {props.data.map((itm, key) => {
        return <LiveFormField key={key} {...itm} styles={props.style} />
      })}
    </Grid>
  );
}

export default LiveFormBuilder