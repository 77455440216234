import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import { ActionTypes } from '@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/types'

import { PictureContent } from "./content";

const Content = props => {
  const { actions } = props.settings;

  const haslink = _.head(_.filter(actions, itm => itm.behavior === "click" &&
    (itm.type === ActionTypes.GO_TO_URL || itm.type === ActionTypes.TRIGGER_NEXT_NODE || itm.type === ActionTypes.DOWNLOAD_FILE || itm.type === ActionTypes.TRIGGER_WEBHOOK)
  ));

  const href = haslink ? _.get(props.settings, "href", _.get(props.settings, "actions[0].payload.url", false)) : false;

  return (
    <>
      <PictureContent
        {...props}
        settings={{
          ...props.settings,
          href
        }}
      />
    </>
  );
};

const Picture = props => {
  const { settings } = props;

  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const padding = settings.properties ? settings.properties.padding : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";
  const display = settings.properties ? settings.properties.display : 'inline-block';
  // const position = settings.properties ? settings.properties.position : 'relative';

  const handleClick = () => {
    const {
      dispatch,
      settings,
      fieldValues,
      updateComponentSettings,
      getComponentSettings
    } = props;
    const { actions } = settings;

    if (actions) {
      const doTheseActions = actions.filter(itm => itm.behavior === "click");
      doTheseActions.forEach(action =>
        dispatch({
          ...action,
          settings: { ...settings, fieldValues },
          updateComponentSettings,
          getComponentSettings
        })
      );
    }
  };

  return (
    <div style={{ marginTop, textAlign }}>
      <div onClick={handleClick} style={{ padding, display, position: 'relative' }}>
        <Content {...props} />
      </div>
    </div>
  );
};

export default connect()(Picture);
