import * as React from "react";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

interface TableProps {
  mode?: EditorMode;
  children?: React.ReactNode;
  style?: any;
  cellStyle?: any;
  footerCellStyle?: any;
  fields: any[];
  data?: any[];
  footer?: any[];
  showHeader?: Boolean;
}

export const Table: React.FC<TableProps> = ({
  style,
  fields = [],
  data = [],
  footer,
  showHeader,
  cellStyle,
  footerCellStyle,
}) => {
  // const cellStyle = {
  //   fontSize: "19pt",
  //   color: "purple",
  // };

  // const footerCellStyle = {
  //   fontWeight: "bold" as "bold",
  // };

  return (
    <TableContainer data-testid="WC-TABLE-LIVE" style={{ ...style }}>
      <MuiTable>
        {showHeader && (
          <TableHead>
            <TableRow>
              {fields.map((field, key) => (
                <TableCell key={key} style={{ ...cellStyle }}>
                  {field}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map((row, key) => (
            <TableRow key={key}>
              {row.map((itm, k) => (
                <TableCell key={k} style={{ ...cellStyle }}>
                  {itm}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {footer && (
          <TableFooter>
            <TableRow>
              {footer.map((itm, key) => (
                <TableCell
                  key={key}
                  style={{ ...cellStyle, ...footerCellStyle }}
                >
                  {itm}
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        )}
      </MuiTable>
    </TableContainer>
  );
};

Table.defaultProps = {
  showHeader: true,
  cellStyle: { fontSize: "9pt" },
  footerCellStyle: { fontWeight: "bold" },
};

export default Table;
