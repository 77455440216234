import actions from "./actions";

export default (state = [], action) => {
  switch (action.type) {
    case "FORM_CONTENT_UPDATE":
      actions.formContentUpdate(action);
      return state;

    default:
      return state;
  }
};
