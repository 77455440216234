import { CSSProperties } from "react";

export enum MobileState {
  FULLSCREEN = "fullscreen",
  DESKTOP = "desktop",
  TABLET = "tablet",
  SMARTPHONE = "smartphone",
}

export type MobileStateObject = {
  type: MobileState;
};

export type MobileResponsiveToggleProps = {
  /**
   * Sets the mobile state and dimensions
   */
  setMobileState: (type: MobileState, dimensions: CSSProperties) => void;

  /**
   * Returns the mobile state and its dimensions
   */
  getMobileState: () => { type: MobileState; dimensions: CSSProperties };
};
