import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

class ButtonContent extends Component {
  constructor(props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    const {
      dispatch,
      settings,
      cKey,
      fieldValues,
      updateComponentSettings,
      getComponentSettings
    } = this.props;
    const { actions } = settings;

    if (actions) {
      const doTheseActions = actions.filter(itm => itm.behavior === "click");
      doTheseActions.forEach(action =>
        dispatch({
          ...action,
          settings: { ...settings, cKey, fieldValues },
          updateComponentSettings,
          getComponentSettings
        })
      );
    }
  }

  render() {
    const { settings } = this.props;
    const { actions } = settings;

    const haslink = _.get(
      _.filter(
        actions,
        itm =>
          itm.behavior === "click" &&
          (itm.type === "GO_TO_URL" || itm.type === "TRIGGER_NEXT_NODE")
      ),
      0,
      false
    );

    // const href = haslink ? _.get(settings, "href", "") : "";

    let href = haslink
      ? _.get(actions, "[0].payload.url", _.get(settings, "href", ""))
      : "";

    const content = (
      <button
        onClick={this.handleButtonClick}
        href={href || settings.href || "#"}
        style={{
          cursor: "pointer",
          ...settings.properties,
          marginTop: 0,
          textAlign: "center"
        }}
      >
        <div
          style={{ display: "inline", color: settings.properties.color, textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: settings.html || "Subscribe Now" }}
        />{" "}
        {settings.secondline ? settings.secondline : null}
      </button>
    );

    return haslink ? <a href={href}>{content}</a> : content;
  }
}

export { ButtonContent };

const LiveButton = props => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <ButtonContent {...props} />
    </div>
  );
};

export default connect()(LiveButton);
