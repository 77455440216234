import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import actions from "./actions";
import selectOptions from "./selectOptions.json";
import style from "./style";

const showAsSelect = name => {
  return (
    name === "country" ||
    name === "state" ||
    name === "cardType" ||
    name === "expirationMonth" ||
    name === "expirationYear" ||
    name === "shippingMethod" ||
    name === "billingModel"
  );
};

const getSelectionOptions = name => {
  // get page options
  if (name === "shippingMethod") {
    return actions.getShippingMethods();
  }

  if (name === "billingModel") {
    return actions.getBillingModels();
  }

  return selectOptions[name];
};

export class TextInputContent extends React.Component {
  constructor(props) {
    super(props);

    const value =
      typeof window === "object"
        ? _.get(
            JSON.parse(_.get(sessionStorage, "formData", "{}")),
            _.get(props, "settings.name", ""),
            ""
          )
        : "";

    this.state = {
      value
    };

    this.doDispatch = this.doDispatch.bind(this);
  }

  doDispatch(e) {
    const { settings, dispatch } = this.props;

    dispatch({
      type: "FORM_CONTENT_UPDATE",
      payload: {
        name: settings.name,
        value: e.target.value
        // cKey
      }
    });

    this.setState({ value: e.target.value });
  }

  render() {
    const { settings } = this.props;

    const type = showAsSelect(settings.name) ? "select" : "text";
    const fieldType = _.get(settings, "fieldType", type);

    if (fieldType === "select") {
      return (
        <div style={{ marginTop: 0, padding: 0 }}>
          <select
            name={settings.name}
            style={{
              ...style.span,
              ...style.select,
              ...settings.properties,
              marginTop: 0
            }}
            onChange={e => this.doDispatch(e)}
            onBlur={e => this.doDispatch(e)}
          >
            {getSelectionOptions(settings.name).map((itm, key) => (
              <option key={key} value={itm.name}>
                {itm.value}
              </option>
            ))}
          </select>
        </div>
      );
    }

    if (fieldType === "text") {
      return (
        <div style={{ marginTop: 0, padding: 0 }}>
          <input
            type="text"
            name={settings.name}
            value={this.state.value}
            placeholder={`${settings.placeholder}${
              _.get(settings, "isRequired", false) ? " *" : ""
            }`}
            // isRequired={
            //   _.get(settings, "isRequired", false) ? "true" : "false"
            // }
            onChange={e => this.doDispatch(e)}
            onBlur={e => this.doDispatch(e)}
            style={{
              ...style.span,
              ...settings.properties,
              marginTop: 0
            }}
          />
        </div>
      );
    }

    if (fieldType === "textarea") {
      return (
        <div style={{ marginTop: 0, padding: 0 }}>
          <textarea
            type="text"
            name={settings.name}
            value={this.state.value}
            placeholder={`${settings.placeholder}${
              _.get(settings, "isRequired", false) ? " *" : ""
            }`}
            // isRequired={
            //   _.get(settings, "isRequired", false) ? "true" : "false"
            // }
            onChange={e => this.doDispatch(e)}
            onBlur={e => this.doDispatch(e)}
            style={{
              ...style.span,
              ...settings.properties,
              marginTop: 0
            }}
          />
        </div>
      );
    }
  }
}

const TextInput = props => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <TextInputContent {...props} />
    </div>
  );
};

export default connect()(TextInput);
