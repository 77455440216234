import * as React from "react";
import { get, has } from "lodash";
// import { Link } from "react-router-dom";
import styled from "styled-components";

import { LinkDisplayTypes, LinkLayoutStyles } from "./types";
// import { Button, ListItem } from "../";
import Button from "../Button/live";
import ListItem from "../ListItem/live";
import { IconSource } from "../Icon/types";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

import presets from "@launchos/plugins/webcomponents/v2/Button/presets";

interface LinkProps {
  mode?: EditorMode;
  filter?: any;
  items: any[];
  linkStyle: any;
  showSocial?: boolean;
  style?: any;
  layoutStyle?: LinkLayoutStyles;
}

const Link = (props) => <a href="#" {...props}>{props.children}</a>;

export const Links: React.FC<LinkProps> = ({
  filter,
  items,
  linkStyle,
  showSocial,
  style,
  layoutStyle = LinkLayoutStyles.HORIZONTAL,
  mode = EditorMode.LIVE,
}) => {
  let links = items;
  if (filter) links = items.filter((itm) => itm.column === filter);

  // display: inline-block;
  const LI = styled.div`
    &:hover {
      text-decoration: underline;
    }
  `;

  const OptionallyRenderAsLink = (props) => {
    if (mode === EditorMode.EDITOR) return props.children;
    return <Link {...props} />;
  };

  return (
    <div
      style={{
        display: layoutStyle === LinkLayoutStyles.HORIZONTAL ? "flex" : "table",
        ...style,
        // margin: "0 auto"
        // float: showSocial ? "none" : "right"
      }}
    >
      {links.map((itm) => {
        if (get(itm, "type", LinkDisplayTypes.TEXT) === LinkDisplayTypes.TEXT) {
          return (
            <OptionallyRenderAsLink
              href={itm.href || "#"}
              style={{ textDecoration: "none" }}
            >
              <LI
                style={{
                  ...linkStyle,
                  listStyle: "none",
                  display:
                    layoutStyle === LinkLayoutStyles.HORIZONTAL
                      ? "inline-block"
                      : "block",
                }}
              >
                {itm.caption}
              </LI>
            </OptionallyRenderAsLink>
          );
        }

        if (get(itm, "type") === LinkDisplayTypes.ICON) {
          return (
            <OptionallyRenderAsLink href={itm.href}>
              <ListItem
                style={{
                  listStyle: "none",
                  ...linkStyle,
                  ...get(itm, "style", {}),
                  display: layoutStyle === LinkLayoutStyles.HORIZONTAL
                    ? "inline-block"
                    : "block",
                }}
                containerStyle={{ display: 'inline-block' }}
                iconSource={get(itm, "iconSource", IconSource.MATERIAL)}
                iconStyle={get(itm, "iconStyle", { paddingRight: 7 })}
                icon={get(itm, "icon")}
                contentStyle={get(itm, "contentStyle", { textAlign: 'center' })}
              >
                {/* {get(itm, 'caption', '')} */}
              </ListItem>
            </OptionallyRenderAsLink>
          );
        }

        if (get(itm, "type") === LinkDisplayTypes.BUTTON) {
          return (
            <LI
              style={{
                listStyle: "none",
                display:
                  layoutStyle === LinkLayoutStyles.HORIZONTAL
                    ? "inline-block"
                    : "block",
                ...linkStyle,
              }}
            >
              <a href={itm.href || "#"}>
                <Button
                  caption1={itm.caption}
                  {...get(itm, "buttonSettings", {})}
                  {...(has(itm, "preset") ? presets[itm.preset] : {})}
                />
              </a>
            </LI>
          );
        }
      })}
      {/* {(mode === EditorMode.EDITOR) && <li style={{ position: 'relative' }} onClick={() => alert("Add Link!")}>(+)</li>} */}
    </div>
  );
};
