// import _ from "lodash";
import React from "react";
import { FacebookProvider, Comments } from "react-facebook";

const Content = props => {
  const { settings } = props;

  let href = ""

  if (typeof window === "object") {
    href = window.location.href
  } else return null;

  return (
    <FacebookProvider appId={settings.appId}>
      <Comments href={href} />
    </FacebookProvider>
  )
};

const Social = props => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <Content {...props} />
    </div>
  );
};

export default Social;
