import * as React from "react";
import { Grid } from "@material-ui/core";

/**
 * A component that shows a LIVE file upload field or tools for configuring the file upload's look, feel, and behavior
 */
export function LiveFileUpload(props) {
  const {
    labelStyle,
    descriptionStyle
  } = props.styles;
  const { columns } = props;
  return (<Grid item xs={columns} data-testid="FormBuilder-FormFields-FileUpload-LIVE">
    {props.label && <div style={labelStyle}>{props.label}</div>}
    {props.description && <div style={descriptionStyle}>{props.description}</div>}
    <input type="file" />
  </Grid>);
}

export default LiveFileUpload