import * as React from "react";

/**
 * A component that shows LIVE text content.  Useful for breaking up pieces of form field content with just text explanations
 */
export function LiveContentField(props) {
  return (<div data-testid="FormBuilder-FormFields-ContentField-LIVE">
    {props.children || props.defaultValue}
  </div>);
}

export default LiveContentField