import _, { first, omitBy, update } from "lodash";
import { getFromCollection } from "./collectionManagement";
import { processRuleGroup } from "./processor";

function daysDiff(providedDate: Date): number {
  try {
    const today = new Date();
    const useThisDate = new Date(providedDate);
    const timeDiff = Math.abs(today.getTime() - useThisDate.getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
    return daysDiff;
  } catch (error) {
    console.log('daysDiff', error);
    // console.log('daysDiff', { useThisDate });
    return 0;
  }
}


const getCollectionData = async (id: string, content: any[]) => {
  if (id) {

    // get the collection data
    const collectionData = await getFromCollection({ id });

    // omit any null keys in the collection data
    const collectionDataOmitNull = omitBy(collectionData, _.isNull);
    // console.log("collectionData", collectionDataOmitNull);

    // place the collection data in the localStorage
    localStorage.setItem(
      "collectionData",
      JSON.stringify(collectionDataOmitNull)
    );
  }

  return content;
};

const getSmartRouteData = async ({ pageId, content, nextPage, getPageData, trackingPayload }) => {
  const { smartRouterSettings = [] } = trackingPayload;
  console.log("smartRouterSettings", smartRouterSettings);

  // pageId to route to
  const routeToPageId = (pageId) => {
    console.log("About to route to pageId", pageId);

    // look up the slug for the pageId
    const slug = getPageData(pageId)?.slug;

    // navigate to that page
    // console.log("Found it.  Navigating to", slug);
    window.location.href = `/${slug}`;

  };

  smartRouterSettings.forEach(async (ruleGroup, key) => {

    const { firstVisit, lastVisit, numSiteVisits, submissionValues = {} } = JSON.parse(localStorage.getItem('siteSettings')) || {};

    const data = {
      // number fields
      daysAfterFirstVisit: daysDiff(firstVisit), // calculate # of days since firstVisit
      daysAfterLastVisit: daysDiff(lastVisit), // calculate # of days since lastVisit,
      numPageVisits: numSiteVisits,

      // string fields
      url: window.location.href,

      // enum fields
      tags: [], // To Do

      // date fields
      currentDate: new Date(),
      startDate: new Date(), // To Do
      endDate: new Date(), // To Do

      // boolean fields
      boolTest: true, // To Do

      // incoming form fields -- To Do <- update submit form to store in sessionStorage, then retrieve here
      ...submissionValues,
    };

    const result = await processRuleGroup(ruleGroup, data);

    // if the result is true, then route to the pageId indicated in the ruleGroup
    if (result === true) routeToPageId(ruleGroup.pageId);

    console.log({ ruleGroup, key, result, data });

  });

  // if nothing was matched, route to the default next Page
  window.location.href = `/${nextPage}`;

  return content;
};

const updatePageVisitCounts = (pageId: string) => {

  // get the stored details from localStorage for this pageId
  const pageSettings = JSON.parse(localStorage.getItem(pageId)) || {};
  // console.log({ pageSettings });

  // extract the values of firstVisit and numPageVisits from pageSettings
  const { firstVisit, numPageVisits = 0 } = pageSettings;

  // update the pageSettings object with the current date and time and 
  // increment the numPageVisits counter
  localStorage.setItem(pageId, JSON.stringify({
    ...pageSettings,
    firstVisit: firstVisit || new Date(),
    lastVisit: new Date(),
    numPageVisits: (numPageVisits || 0) + 1
  }));

};

const updateSiteVisitCounts = () => {
  
    // get the stored details from localStorage for this pageId
    const siteSettings = JSON.parse(localStorage.getItem("siteSettings")) || {};
    // console.log({ siteSettings });
  
    // extract the values of firstVisit and numPageVisits from pageSettings
    const { firstVisit, numSiteVisits = 0 } = siteSettings;
  
    // update the pageSettings object with the current date and time and 
    // increment the numPageVisits counter
    localStorage.setItem("siteSettings", JSON.stringify({
      ...siteSettings,
      firstVisit: firstVisit || new Date(),
      lastVisit: new Date(),
      numSiteVisits: (numSiteVisits || 0) + 1 // To Do: Site visits currently include page visits ... e.g. if there are 10 page visits in a session then there are 10 site visits and not 1
    }));
}

export const beforeContentLoads = async ({pageId, content, nextPage, getPageData, trackingPayload}) => {
  
  // get the URL search parameters
  let params = new URLSearchParams(location.search) 

  // get the id from the query string
  let id = params.get('id')

  // if there's an id, get the data from the collection db
  const processedContentFromCollections = await getCollectionData(id, content);

  // if there is a smart route, process it now
  const processedContentFromSmartRoutes = trackingPayload?.smartRouterSettings
    ? await getSmartRouteData({
        pageId,
        content: processedContentFromCollections,
        nextPage,
        getPageData,
        trackingPayload,
      })
    : processedContentFromCollections;
  
    
  const finalContent = processedContentFromSmartRoutes;
  
  console.log("preProcessContent", finalContent);

  // increment local storage page visit counts
  updatePageVisitCounts(pageId);
  updateSiteVisitCounts();

  return finalContent;
};
