import React from "react";
import {
  getColumnsWrapperStyle,
  getColumnsInnerStyle,
  getColumnStyle,
} from "./style";

export const Columns = (props) => (
  <div style={getColumnsWrapperStyle(props)}>
    <div style={getColumnsInnerStyle(props)}>{props.children}</div>
  </div>
);

export const Column = (props) => {
  return <div style={getColumnStyle(props)}>{props.children}</div>;
};

export default Columns;
