import { omit } from "lodash";

export const addToCollection = async (options, payload) => {
  // push data to a collection if requested
  const collectionData = omit(payload, [
    "creditCardType",
    "creditCardNumber",
    "expirationDate",
    "CVV",
    "emP",
    "pmP",
    "pageId",
    "credentials",
  ]);

  // console.log({
  //   options,
  //   payload: collectionData,
  // });

  const domain =
    window.location.hostname === "localhost"
      ? "http://localhost:8091"
      : "https://api.funnels.cc";

  const use_this_url = `${domain}/v1/collections`;

  const response = await fetch(use_this_url, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      options,
      data: collectionData,
    }),
  });

  const resp = await response.json();

  console.log({ resp });
  return resp;
};

export const getFromCollection = async ({ collectionId = "clkx7ocb68nna08117p0x7hcq-crm", id }) => {

  const domain =
    window.location.hostname === "localhost"
      ? "http://localhost:8091"
      : "https://api.funnels.cc";

  const use_this_url = `${domain}/v1/collections/${collectionId}/${id}`;

  const response = await fetch(use_this_url, {
    method: "GET",
    headers: { "content-type": "application/json" },
  });

  const resp = await response.json();

  console.log('getFromCollection', { resp });
  return resp;
}