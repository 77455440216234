import React from "react";
import { Helmet } from "react-helmet";

export const CSS: React.FC<{ payload: string }> = ({ payload }) => (
  <Helmet>
    <style type="text/css">
      {payload}
    </style>
  </Helmet>
);
