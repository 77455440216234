import _ from "lodash";
import * as React from "react";
import { selectOptions } from "../../EditFormField/fieldNames"

/**
 * A component that shows a LIVE list of checkboxes or tools for configuring the group of checkboxes look, feel, and behavior
 */
export function LiveCheckboxes(props) {
  const { data = selectOptions.defaultOptions, handleChange = () => null } = props;
  const {
    labelStyle, descriptionStyle, inputStyle, containerStyle
  } = props.styles;
  return (<div data-testid="FormBuilder-FormFields-Checkboxes-LIVE">
    {props.label && <div style={labelStyle}>{props.label}</div>}
    {props.description && <div style={descriptionStyle}>{props.description}</div>}
    {data && data.map(({
      id, label, value
    }, index) => <div key={id} data-testid="FormBuilder-FormFields-Checkboxes-Checkbox" style={{ ...inputStyle, marginBottom: index < (data.length - 1) ? _.get(containerStyle, 'marginBottom', 'inherit') : 'inherit' }}>
        <input id={id} type="checkbox" onChange={e => handleChange({
          checked: e.checked,
          id
        })} style={{
          marginRight: 10,
          top: 2, position: 'relative'
        }} />
        <label for={id}>{label || value}</label>
      </div>)}
  </div>);
}

export default LiveCheckboxes