import React from "react";
import theme from "@launchos/components/ui/theme";
import { get, head } from "lodash";
import { Border } from "@launchos/modules/editor/Builder/EditorObject/style";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

/**
 * Find out if any of the columns in this row currently have a "hover" state
 * @param {*} param0 
 */
const isHoveringOnAnyColumnsInThisRow = ({ pageContent, settings: { parent } }) => {
  // get each column
  const getColumns = pageContent.filter((itm) => itm.parent === parent);
  return getColumns.findIndex(({ state }) => state === "hover") > -1;
};

/**
 * Find out if any element in any of the adjacent columns have a "hover" state
 * @param {*} param0 
 */
export default (props) => {
  const { settings, show, borderSize, mode = EditorMode.LIVE, isEmpty = false, EmptyState } = props;
  const style = get(settings, "properties", {});

  return (
    <>
      {(isHoveringOnAnyColumnsInThisRow(props) && show === "builder") && (
        <Border
          borderSize={borderSize}
          show={show}
          settings={settings}
          color={theme.altColors[1]}
          state="hover"
        />
      )}
      <div
        style={{
          ...style,
          ...props.style,
          width: "100%",
          height: "100%",
        }}
      >
        {(isEmpty && mode === EditorMode.EDITOR) ? <EmptyState {...props} /> : props.children}
      </div>
    </>
  );
};
